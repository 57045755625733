





















import { debounce } from 'lodash';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { dataLabApiService } from '../../services';
import { Campaign, CampaignType, Client } from '../../models';

@Component
export default class SearchCampaign extends Vue {
  @Prop() readonly label!: string;

  @Prop() readonly placeholder!: string;

  @Prop() readonly client!: Client;

  @Prop() readonly campaignType!: CampaignType[];

  private campaigns: Campaign[] = [];

  private isLoading: boolean = false;

  private autocomplete = debounce(search => this.searchCampaign(search), 350);

  data() {
    return {
      mlabel: this.label || 'Campaign',
      mplaceholder: this.placeholder || 'Search campaigns',
    };
  }

  private async searchCampaign(search: string) {
    if (!this.client) {
      throw new Error('Must have a client bounded.');
    }

    if (search.length === 0) {
      this.campaigns = [];
      return;
    }

    this.isLoading = true;
    const { data } = await dataLabApiService.searchCampaignsOfTypes(
      this.client.clientId,
      search,
      (this.campaignType || []),
    );

    this.campaigns = data;
    this.isLoading = false;
  }

  private select(value: Campaign) {
    this.$emit('select', value);
  }
}
