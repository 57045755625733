




















import {
  Component,
  Vue,
  Prop,
  Emit,
} from 'vue-property-decorator';

export interface ActionBoxProps {
  title: string,
  description: string,
  icon: string,
  notionPageUrl?: string,
  action?: () => void,
  modal?: Vue.VueConstructor
}

@Component
export default class ActionBox extends Vue {
  @Prop() readonly title!: string;

  @Prop() readonly description!: string;

  @Prop() readonly icon!: string;

  @Prop() readonly notionPageUrl!: string;

  @Prop() readonly action!: Function;

  @Prop() readonly modal!: Vue.VueConstructor;

  onClick() {
    if (this.modal) {
      this.openModal(this.modal);
    } else if (this.action) {
      this.action();
    }
  }

  @Emit('modal-selected')
  openModal(modal: Vue.VueConstructor) {
    return modal;
  }
}
