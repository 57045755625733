



















import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { dataLabApiService } from '../../services';
import { Client, Campaign, Question } from '../../models';

@Component
export default class SelectQuestion extends Vue {
  @Prop() readonly label!: string;

  @Prop() readonly placeholder!: string;

  @Prop() readonly client!: Client;

  @Prop() readonly campaign!: Campaign;

  private selectedQuestion: Question | null = null;

  private questions: Question[] = [];

  private isLoading: boolean = false;

  data() {
    return {
      mlabel: this.label || 'Questions',
      mplaceholder: this.placeholder || 'Select a questions',
    };
  }

  reset() {
    this.selectedQuestion = null;
  }

  @Watch('client')
  @Watch('campaign')
  private async onPropChange() {
    if (this.client && this.campaign) {
      await this.getQuestions();
    }
  }

  private async getQuestions() {
    if (!this.client) {
      throw new Error('Must have a client bounded.');
    }

    if (!this.campaign) {
      throw new Error('Must have a campaign bounded.');
    }

    const { clientId } = this.client;
    const { campaignId } = this.campaign;

    this.isLoading = true;
    const { data } = await dataLabApiService.getQuestions(clientId, campaignId);
    this.questions = data;
    this.isLoading = false;
  }

  private select(value: Question) {
    this.$emit('select', value);
  }
}
