
































































































































import { ToastProgrammatic as Toast } from 'buefy';
import { Component, Vue, Ref } from 'vue-property-decorator';
import { dataLabApiService } from '../../services';
import { Client } from '../../models';
import PasswordWithGeneratorField from '../global/PasswordWithGeneratorField.vue';

@Component({
  components: {
    PasswordWithGeneratorField,
  },
})
export default class SendFileWithArchiveStoreModal extends Vue {
  private recipient: string | null = null;

  private files: File[] = [];

  private sender: string | null = null;

  private zipPassword: string | null = null;

  private isSending: boolean = false;

  @Ref('passwordField')
  private passwordField!: PasswordWithGeneratorField;

  async send() {
    if (
      !this.recipient || this.recipient === ''
      || !this.sender || this.sender === ''
      || !this.zipPassword || this.zipPassword === ''
    ) {
      Toast.open({
        message: 'Please complete all the fields',
        type: 'is-danger',
        position: 'is-bottom',
        duration: 3000,
      });
      return;
    }

    this.isSending = true;

    // Get the parameters
    const {
      recipient,
      sender,
      zipPassword,
    } = this;

    // Call
    // const result = await dataLabApiService.sendChurnExport(
    //   clientId,
    //   csmEmail,
    //   clientEmail,
    //   zipPassword,
    // );

    // // Display success message
    // Notification.open({
    //   message: 'Your request as been completed successfuly.',
    //   position: 'is-top-right',
    //   type: 'is-success',
    //   indefinite: true,
    // });

    // // Reset values
    // this.isSending = false;
    // this.selectedClient = null;
    // this.csmEmail = null;
    // this.clientEmail = null;
    // this.zipPassword = null;
    // this.passwordField.reset();

    // // Emit close event so that the modal is closed
    // this.$emit('close');
  }
}
