





















import {
  Component,
  Vue,
  Prop,
  Ref,
} from 'vue-property-decorator';
import ActionBox, { ActionBoxProps } from './ActionBox.vue';
import { EventBus } from '../../helpers';
import { API_ON_ERROR } from '../../services';

@Component({
  components: {
    ActionBox,
  },
})
export default class ActionList extends Vue {
  @Prop(Array) readonly actions!: ActionBoxProps[];

  private currentModal: Vue.VueConstructor | null = null;

  private currentAction: ActionBoxProps | null = null;

  private currentModalOpen: boolean = false;

  created() {
    // On api error close this modal
    EventBus.$on(API_ON_ERROR, (error: any) => {
      if (this.currentModal) {
        this.currentModalOpen = false;
        this.currentModal = null;
        this.currentAction = null;
      }
    });
  }

  private setCurrentModal(modal: Vue.VueConstructor) {
    this.currentModal = modal;
    this.currentModalOpen = true;
    this.currentAction = this.actions.find(action => action.modal === modal) || null;
  }
}
