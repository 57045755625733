import { Sso } from './Sso';

export class SsoManager {
  public ssoEnabled: boolean;

  public qualifioLogin: boolean;

  public ssoList: Sso[];

  constructor(ssoEnabled: boolean, qualifioLogin: boolean, ssoList: Sso[]) {
    this.ssoEnabled = ssoEnabled;
    this.qualifioLogin = qualifioLogin;
    this.ssoList = ssoList;
  }
}
