



















import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { dataLabApiService } from '../../services';
import { Client, Campaign, Channel } from '../../models';

@Component
export default class SelectChannel extends Vue {
  @Prop() readonly label!: string;

  @Prop() readonly placeholder!: string;

  @Prop() readonly client!: Client;

  @Prop() readonly campaign!: Campaign;

  private channels: Channel[] = [];

  private selectedChannel: Channel | null = null;

  private isLoading: boolean = false;

  data() {
    return {
      mlabel: this.label || 'Channels',
      mplaceholder: this.placeholder || 'Select a channel',
    };
  }

  @Watch('client')
  @Watch('campaign')
  private async onPropChange() {
    if (this.client && this.campaign) {
      await this.getChannels();
    }
  }

  private async getChannels() {
    if (!this.client) {
      throw new Error('Must have a client bounded.');
    }

    if (!this.campaign) {
      throw new Error('Must have a campaign bounded.');
    }

    const { clientId } = this.client;
    const { campaignId } = this.campaign;

    this.isLoading = true;
    const { data } = await dataLabApiService.getChannels(clientId, campaignId);
    this.channels = data;
    if (this.channels.length > 0) {
      [this.selectedChannel] = this.channels;
      this.select(this.channels[0]);
    }
    this.isLoading = false;
  }

  private select(value: Channel) {
    this.$emit('select', value);
  }
}
