

































































































import {
  NotificationProgrammatic as Notification,
  ToastProgrammatic as Toast,
} from 'buefy';
import {
  Component,
  Vue,
  Ref,
  Prop,
} from 'vue-property-decorator';
import { dataLabApiService, authAdminApiService } from '../../services';
import { Client } from '../../models';
import SearchClient from '../global/SearchClient.vue';
import PasswordWithGeneratorField from '../global/PasswordWithGeneratorField.vue';

@Component({
  components: {
    SearchClient,
    PasswordWithGeneratorField,
  },
})
export default class ExportClientChurnModal extends Vue {
    @Prop()
  private title!: string;

  @Prop()
  private notionPageUrl!: string;

  @Prop()
  private icon!: string;

  private selectedClient: Client | null = null;

  private isLoadingClientEmails: boolean = false;

  private clientEmails: string[] = [];

  private clientEmail: string | null = null;

  private csmEmail: string | null = null;

  private zipPassword: string | null = null;

  private isSending: boolean = false;

  private isBusy: boolean = false;

  @Ref('passwordField')
  private passwordField!: PasswordWithGeneratorField;

  private async selectClient(client: Client) {
    if (client === null) return;

    this.selectedClient = client;
    this.isLoadingClientEmails = true;

    this.clientEmails = await authAdminApiService.getTenantUsers(this.selectedClient.clientId);

    this.isLoadingClientEmails = false;
  }

  async sendExport() {
    if (
      !this.selectedClient
      || !this.csmEmail || this.csmEmail === ''
      || !this.clientEmail || this.clientEmail === ''
      || !this.zipPassword || this.zipPassword === ''
    ) {
      Toast.open({
        message: 'Please complete all the fields',
        type: 'is-danger',
        position: 'is-bottom',
        duration: 3000,
      });
      return;
    }
    this.isBusy = false;
    this.isSending = true;

    // Get the parameters
    const { clientId } = this.selectedClient;
    const {
      csmEmail,
      clientEmail,
      zipPassword,
    } = this;

    // Call
    const result = await dataLabApiService.sendChurnExport(
      clientId,
      csmEmail,
      clientEmail,
      zipPassword,
    );

    if (result.status === 429) { // server busy
      this.isSending = false;
      this.isBusy = true;
    } else {
      // Display success message
      Notification.open({
        message: 'Hurray! You did it.<br/> We will e-mail you when the file has been sent.',
        position: 'is-top-right',
        type: 'is-success',
        indefinite: true,
      });

      // Reset values
      this.isSending = false;
      this.selectedClient = null;
      this.csmEmail = null;
      this.clientEmail = null;
      this.zipPassword = null;
      if (this.passwordField) {
        this.passwordField.reset();
      }

      // Emit close event so that the modal is closed
      this.$emit('close');
    }
  }
}
