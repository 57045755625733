export class Campaign {
  public campaignId: number;

  public title: string;

  constructor(campaignId: number, title: string) {
    this.campaignId = campaignId;
    this.title = title;
  }
}

export enum CampaignType {
  QUIZZ_AND_CONTEST = 'QUIZZ_AND_CONTEST',
  SURVEY_AND_POLL = 'SURVEY_AND_POLL',
  FORM = 'FORM',
  PERSONNALITY_TESTS = 'PERSONNALITY_TESTS',
  CHRONO_QUIZZ = 'CHRONO_QUIZZ',
  INSTANT_WIN = 'INSTANT_WIN',
  PHOTO_CONTESTS = 'PHOTO_CONTESTS',
  WRITING_CONTESTS = 'WRITING_CONTESTS',
  VIDEO_CONTESTS = 'VIDEO_CONTESTS',
  ANIMATED_GAMES = 'ANIMATED_GAMES',
  OTHERS = 'OTHERS',
}
