export class Sso {
  public domain?: string;

  public sso?: string;

  constructor(domain?: string, sso?: string) {
    this.domain = domain;
    this.sso = sso;
  }
}
