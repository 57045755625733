import { DialogProgrammatic as Dialog } from 'buefy';
import axios, { AxiosInstance, AxiosError } from 'axios';
import store from '../store';
import {
  Client,
  Campaign,
  Sso,
  SsoManager,
  ConfigSsoManager,
  Channel,
  Question,
  IdentityProvider,
} from '../models';
import { apiConfig, EventBus } from '../helpers';

export const API_ON_ERROR = 'API_ON_ERROR';

class DataLabApiService {
  private axios: AxiosInstance;

  private acceptedStatusCodeAsResponse: number[] = [404, 429];

  private getToken: () => string = store.getters.token;

  private apiErrorHandler = (error: AxiosError) => {
    EventBus.$emit(API_ON_ERROR, error);
    Dialog.alert({
      title: 'Oops! Something bad happened!',
      message: 'We apologise for any inconvenience, but an unexpected error occurred. Try again?<br/><br/> The I-Team may be able to help you.',
      type: 'is-danger',
      hasIcon: true,
      icon: 'times-circle',
      confirmText: 'Ok, got it',
    });
    return Promise.reject(error);
  }

  constructor() {
    this.axios = axios.create({
      baseURL: apiConfig.fullPath,
      headers: {
        'Content-Type': 'application/json',
      },
      validateStatus: status => (
        (status >= 200 && status < 300) // default
        || this.acceptedStatusCodeAsResponse.includes(status)
      ),
      timeout: 0, // 0 = no timeout
    });

    this.axios.interceptors.request.use((req) => {
      if (req && req.headers) {
        // eslint-disable-next-line no-param-reassign
        req.headers['x-api-token'] = this.getToken();
      }
      return req;
    });

    // Handle api call error
    this.axios.interceptors.response.use(
      response => response,
      this.apiErrorHandler,
    );
  }

  getClients() {
    return this.axios.get<Client[]>('clients');
  }

  getCampaigns(clientId: number) {
    return this.axios.get<Campaign[]>('campaigns', {
      params: {
        clientId,
      },
    });
  }

  searchCampaignsOfTypes(clientId: number, searchName: string, campaignTypes: string[]) {
    return this.axios.get<Campaign[]>('campaigns', {
      params: {
        clientId,
        searchName,
        campaignTypes,
      },
    });
  }

  getGStatsCampaignsExport(clientId: number, startAt: string, endAt: string) {
    return this.axios.get<string>('export/gstats/campaigns', {
      params: {
        clientId,
        startAt,
        endAt,
      },
    });
  }

  getGStatsSitesExport(clientId: number, startAt: string, endAt: string) {
    return this.axios.get<string>('export/gstats/sites', {
      params: {
        clientId,
        startAt,
        endAt,
      },
    });
  }

  getPlanningExport(clientId: number, campaignId: number) {
    return this.axios.get<string>('export/planning', {
      params: {
        clientId,
        campaignId,
      },
    });
  }

  importPlanning(clientId: number, campaignId: number, file: File) {
    const formData = new FormData();
    formData.append('planning', file);

    return this.axios.post('import/planning', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-api-token': this.getToken(),
      },
      params: {
        clientId,
        campaignId,
      },
    });
  }

  getNbOfCampaignsCreatedPerUser(clientId: number) {
    return this.axios.get<string>('export/user-list-export', {
      params: {
        clientId,
      },
    });
  }

  getClientsIntegrations() {
    return this.axios.get<string>('export/clients-integrations');
  }

  sendChurnExport(clientId: number, csmEmail: string, clientEmail: string, zipPassword: string) {
    return this.axios.post<string>('export/offboarding', {
      clientId,
      csmEmail,
      clientEmail,
      zipPassword,
    }, {
      timeout: 0,
    });
  }

  async getClientSSOManager(clientId: number): Promise<SsoManager> {
    const response = await this.axios.get<ConfigSsoManager>(`clients/${clientId}/configSsoManager`, {
      params: {
        ts: new Date().getTime(), // by-pass varnish cache to prevent bad UX
      },
    });

    if (response.data && response.status === 200) {
      const { data } = response;
      const result = new SsoManager(
        data.ssoEnabled,
        data.qualifioLogin,
        [],
      );

      data.ssoList.forEach((item) => {
        const sso = item.split(/\.(.+)/);
        result.ssoList.push(new Sso(sso[1], sso[0]));
      });

      return result;
    }

    return new SsoManager(false, false, []);
  }

  getAvailableIdentityProviders() {
    return this.axios.get<IdentityProvider[]>('identity-providers');
  }

  putClientSSOManager(
    clientId: number,
    ssoEnabled: boolean,
    qualifioLogin: boolean,
    ssoList: string[],
  ) {
    return this.axios.put<string[]>(`clients/${clientId}/configSsoManager`, {
      ssoEnabled,
      qualifioLogin,
      ssoList,
    });
  }

  getChannels(
    clientId: number,
    campaignId: number,
  ) {
    return this.axios.get<Channel[]>(`campaigns/${campaignId}/channels`, {
      params: {
        clientId,
      },
    });
  }

  getQuestions(
    clientId: number,
    campaignId: number,
  ) {
    return this.axios.get<Question[]>(`campaigns/${campaignId}/questions`, {
      params: {
        clientId,
      },
    });
  }

  getSurveyResults(
    clientId: number,
    campaignId: number,
    channelId: number,
    questionId: number,
  ) {
    return this.axios.get<string>('export/survey-results', {
      params: {
        clientId,
        campaignId,
        channelId,
        questionId,
      },
    });
  }
}

// Export a singleton instance in the global namespace
export const dataLabApiService = new DataLabApiService();
