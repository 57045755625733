




































import { ToastProgrammatic as Toast } from 'buefy';
import dayjs from 'dayjs';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { dataLabApiService } from '../../services';
import { Client } from '../../models';
import { triggerDownloadFile } from './helpers';
import SearchClient from '../global/SearchClient.vue';

@Component({
  components: {
    SearchClient,
  },
})
export default class ExportNbCampaignsPerUserModal extends Vue {
  @Prop()
  private title!: string;

  @Prop()
  private notionPageUrl!: string;

  @Prop()
  private icon!: string;

  private selectedClient: Client | null = null;

  private isExporting: boolean = false;

  private async generateExport() {
    if (!this.selectedClient) {
      Toast.open({
        message: 'Please complete all the fields',
        type: 'is-danger',
        position: 'is-bottom',
        duration: 3000,
      });
      return;
    }
    this.isExporting = true;

    // Get the parameters
    const { clientId } = this.selectedClient;

    // Call
    const result = await dataLabApiService.getNbOfCampaignsCreatedPerUser(clientId);

    // Trigger the download
    triggerDownloadFile('export.csv', result.data);

    // Reset values
    this.isExporting = false;
    this.selectedClient = null;

    // Emit close event so that the modal is closed
    this.$emit('close');
  }
}
