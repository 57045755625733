import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    kcToken: null,
  },
  getters: {
    token: state => () => state.token,
    kcToken: state => () => state.kcToken,
  },
  mutations: {
    updateJwtToken(state, token) {
      state.token = token;
    },
    updateKeycloakToken(state, token) {
      state.kcToken = token;
    },
  },
  actions: {
  },
  modules: {
  },
});
