







import { Component, Vue } from 'vue-property-decorator';
import ActionList from '../components/global/ActionList.vue';
import { ActionBoxProps } from '../components/global/ActionBox.vue';
import SendFileWithArchiveStoreModal from '../components/archiveStore/SendFileWithArchiveStoreModal.vue';

@Component({
  components: {
    ActionList,
  },
})
export default class ArchiveStore extends Vue {
  private actions: ActionBoxProps[] = [
    {
      title: 'Send file(s) securely',
      description: 'File(s) containing either personal data or credentials must be send with the Archive Store.',
      icon: 'paper-plane',
      modal: SendFileWithArchiveStoreModal,
    },
  ];
}
