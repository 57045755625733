import axios, { AxiosError, AxiosInstance } from 'axios';
import { DialogProgrammatic as Dialog } from 'buefy';
import { authAdminApiConfig, EventBus } from '../helpers';
import { User } from '../models';
import store from '../store';
import { API_ON_ERROR } from './dataLabApiService';

class AuthAdminApiService {
  private axios: AxiosInstance;

  private acceptedStatusCodeAsResponse: number[] = [404, 429];

  private getToken: () => string = store.getters.kcToken;

  private apiErrorHandler = (error: AxiosError) => {
    EventBus.$emit(API_ON_ERROR, error);
    Dialog.alert({
      title: 'Oops! Something bad happened!',
      message: 'We apologise for any inconvenience, but an unexpected error occurred. Try again?<br/><br/> The I-Team may be able to help you.',
      type: 'is-danger',
      hasIcon: true,
      icon: 'times-circle',
      confirmText: 'Ok, got it',
    });
    return Promise.reject(error);
  }

  constructor() {
    this.axios = axios.create({
      baseURL: authAdminApiConfig.fullPath,
      headers: {
        'Content-Type': 'application/json',
      },
      validateStatus: status => (
        (status >= 200 && status < 300) // default
        || this.acceptedStatusCodeAsResponse.includes(status)
      ),
      timeout: 0, // 0 = no timeout
    });

    this.axios.interceptors.request.use((req) => {
      if (req && req.headers) {
        // eslint-disable-next-line no-param-reassign
        req.headers.Authorization = `Bearer ${this.getToken()}`;
      }
      return req;
    });

    // Handle api call error
    this.axios.interceptors.response.use(
      response => response,
      this.apiErrorHandler,
    );
  }

  async getTenantUsers(tenantId: number, application: string = 'engage') {
    let users: string[] = [];

    const result = await this.axios.get<User[]>('users', {
      params: {
        tenantId,
        application,
      },
    });

    if (result.data && result.status === 200) {
      users = result.data.reduce((acc, user) => {
        if (user.enabled) {
          acc.push(user.email);
        }
        return acc;
      }, users);
    }

    return users;
  }
}

// Export a singleton instance in the global namespace
export const authAdminApiService = new AuthAdminApiService();
