



















import { Component, Vue } from 'vue-property-decorator';
import ExportStatsCampaignsModal from '../components/stats/ExportStatsCampaignsModal.vue';
import ExportStatsSitesModal from '../components/stats/ExportStatsSitesModal.vue';
import ExportNbCampaignsPerUserModal from '../components/stats/ExportNbCampaignsPerUserModal.vue';
import ExportClientChurnModal from '../components/stats/ExportClientChurnModal.vue';
import ExportSurveyResults from '../components/stats/ExportSurveyResults.vue';
import ExportClientsEnabledIntegrations from '../components/stats/ExportClientsEnabledIntegrations.vue';
import ActionList from '../components/global/ActionList.vue';
import { ActionBoxProps } from '../components/global/ActionBox.vue';

@Component({
  components: {
    ActionList,
  },
})
export default class Stats extends Vue {
  private statsActions: ActionBoxProps[] = [
    {
      title: 'Campaign list export',
      description: 'Export all of a customer\'s campaigns within a specified date range.',
      icon: 'download',
      notionPageUrl: 'https://www.notion.so/qualifio/Export-stats-per-campaign-1fce0926db3b449a8c496eecafcc5b9e',
      modal: ExportStatsCampaignsModal,
    },
    {
      title: 'Site list export',
      description: 'Export all of a customer\'s sites within a specified date range, sorted by number of campaigns.',
      icon: 'download',
      notionPageUrl: 'https://www.notion.so/qualifio/Export-stats-per-sites-e11e8c5b5569498b8668b88960be1c6f',
      modal: ExportStatsSitesModal,
    },
    {
      title: 'User list export',
      description: 'Export all of an account\'s users, sorted by number of campaigns created in all of the account\'s history.',
      icon: 'download',
      notionPageUrl: 'https://www.notion.so/qualifio/Snapshot-of-account-activity-campaigns-created-per-user-014302c757f94560b543c4ae53b7fd82',
      modal: ExportNbCampaignsPerUserModal,
    },
    {
      title: 'Offboarding export',
      description: 'Create and share a ZIP file with any account user to give them access to their account\'s data.',
      icon: 'cogs',
      notionPageUrl: 'https://www.notion.so/qualifio/Export-for-churn-bf7a4648c5f1488dac056aa754175c6d',
      modal: ExportClientChurnModal,
    },
    {
      title: 'Survey/poll results export',
      description: 'Export results of a survey or poll campaign.',
      icon: 'download',
      notionPageUrl: 'https://www.notion.so/qualifio/Survey-Poll-results-export-c03acc0d19434668a175da2c5c00d799',
      modal: ExportSurveyResults,
    },
    {
      title: 'Export clients integrations list',
      description: 'Export clients integrations list.',
      icon: 'download',
      notionPageUrl: '',
      modal: ExportClientsEnabledIntegrations,
    },
  ];
}
