



































import { Component, Vue, Prop } from 'vue-property-decorator';
import { dataLabApiService } from '../../services';
import { triggerDownloadFile } from './helpers';

@Component({
  components: {},
})
export default class ExportClientsEnabledIntegrations extends Vue {
  @Prop()
  private title!: string;

  @Prop()
  private notionPageUrl!: string;

  @Prop()
  private icon!: string;

  private isExporting: boolean = false;

  private async generateExport() {
    this.isExporting = true;

    // Call
    const result = await dataLabApiService.getClientsIntegrations();

    // Trigger the download
    triggerDownloadFile('export.csv', result.data);

    // Reset values
    this.isExporting = false;

    // Emit close event so that the modal is closed
    this.$emit('close');
  }
}
