



















import { Component, Vue, Prop } from 'vue-property-decorator';
import { dataLabApiService } from '../../services';
import { Client } from '../../models';

@Component
export default class SearchClient extends Vue {
  @Prop() readonly label!: string;

  @Prop() readonly placeholder!: string;

  private clients: Client[] = [];

  private clientsFiltered: Client[] = [];

  private isLoading: boolean = false;

  data() {
    return {
      mlabel: this.label || 'Customer account',
      mplaceholder: this.placeholder || 'Search accounts',
    };
  }

  mounted() {
    this.getClients();
  }

  private async getClients() {
    this.isLoading = true;
    const { data } = await dataLabApiService.getClients();
    this.clients = data;
    this.clientsFiltered = this.clients;
    this.isLoading = false;
  }

  private search(input: string) {
    if (input === '') {
      this.clientsFiltered = this.clients;
      return;
    }

    const clientId = parseInt(input, 10);
    // If not a number, filter on the name
    if (Number.isNaN(clientId)) {
      this.clientsFiltered = this.clients.filter(
        c => c.name.toLowerCase().indexOf(input.toLowerCase()) > -1,
      );
    } else {
      // Else it's a number, filter on it
      this.clientsFiltered = this.clients.filter(c => `${c.clientId}`.indexOf(input) > -1);
    }
  }

  private select(value: Client) {
    this.$emit('select', value);
  }
}
