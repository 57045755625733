



























































import { ToastProgrammatic as Toast } from 'buefy';
import dayjs from 'dayjs';
import {
  Component,
  Vue,
  Watch,
  Prop,
} from 'vue-property-decorator';
import { dataLabApiService } from '../../services';
import { Client } from '../../models';
import { triggerDownloadFile } from './helpers';
import SearchClient from '../global/SearchClient.vue';

const dateFormat = 'YYYY-MM-DD';

@Component({
  components: {
    SearchClient,
  },
})
export default class ImportPlanningModal extends Vue {
  @Prop()
  private title!: string;

  @Prop()
  private notionPageUrl!: string;

  @Prop()
  private icon!: string;

  private selectedClient: Client | null = null;

  private selectedDates: Date[] = [];

  private formattedSelectedDates: string[] = [];

  private isExporting: boolean = false;

  @Watch('selectedDates')
  onPropertyChanged(value: Date[]) {
    this.formattedSelectedDates = [
      dayjs(value[0]).format(dateFormat),
      dayjs(value[1]).format(dateFormat),
    ];
  }

  private async generateExport() {
    if (
      !this.formattedSelectedDates
      || this.formattedSelectedDates.length !== 2
      || !this.selectedClient
    ) {
      Toast.open({
        message: 'Please complete all the fields',
        type: 'is-danger',
        position: 'is-bottom',
        duration: 3000,
      });
      return;
    }
    this.isExporting = true;

    // Get the parameters
    const { clientId } = this.selectedClient;
    const startAt = this.formattedSelectedDates[0];
    const endAt = this.formattedSelectedDates[1];

    // Call
    const result = await dataLabApiService.getGStatsCampaignsExport(clientId, startAt, endAt);

    // Trigger the download
    triggerDownloadFile('export.csv', result.data);

    // Reset values
    this.isExporting = false;
    this.selectedDates = [];
    this.selectedClient = null;

    // Emit close event so that the modal is closed
    this.$emit('close');
  }
}
