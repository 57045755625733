import VueRouter, { Route } from 'vue-router';
import { Store } from 'vuex';

export const storeTokenFromRoute = async (
  router: VueRouter,
  route: Route,
  store: Store<any>,
) => {
  // Copy the query, otherwise it's not considered as modified below
  const query = Object.assign({}, route.query);
  const { token, kcToken } = query;

  if (!token) {
    throw new Error('Authentication failed: missing required parameter `token`');
  }

  // Save tokens in the store
  store.commit('updateJwtToken', token);
  if (kcToken) {
    store.commit('updateKeycloakToken', kcToken);
  }

  // Remove it from the path
  // delete query.token;
  // await router.replace({
  //   query,
  // });
};
