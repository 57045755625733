



















































































































































import {
  DialogProgrammatic as Dialog,
  ToastProgrammatic as Toast,
  NotificationProgrammatic as Notification,
} from 'buefy';
import {
  Component,
  Vue,
  Ref,
  Prop,
} from 'vue-property-decorator';
import { dataLabApiService } from '../../services';
import { Client, Sso, IdentityProvider } from '../../models';
import SearchClient from '../global/SearchClient.vue';

const REG_DOMAIN_VALID = /^(?:\*\.)?(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;

@Component({
  components: {
    SearchClient,
  },
})
export default class SsoManager extends Vue {
  @Prop()
  private title!: string;

  @Prop()
  private notionPageUrl!: string;

  @Prop()
  private icon!: string;

  private selectedClient: Client | null = null;

  private isLoadingClientSso: boolean = false;

  private isLoadingIdps: boolean = false;

  private ssoEnabled: boolean = false;

  private disableQualifioLogin: boolean = false;

  private ssoList: Sso[] = [];

  private ssoToAdd: Sso = new Sso();

  private isSaving: boolean = false;

  private idps: IdentityProvider[] = [];

  mounted() {
    this.reset();
  }

  private async selectClient(client: Client) {
    if (client === null) return;

    this.selectedClient = client;
    this.isLoadingClientSso = true;
    this.isLoadingIdps = true;

    const result = await dataLabApiService.getClientSSOManager(this.selectedClient.clientId);

    this.ssoList = result.ssoList;
    this.ssoEnabled = result.ssoEnabled;
    this.disableQualifioLogin = !result.qualifioLogin;

    const { data } = await dataLabApiService.getAvailableIdentityProviders();
    this.idps = data;

    this.isLoadingClientSso = false;
    this.isLoadingIdps = false;
  }

  private addSso() {
    if (
      !this.ssoToAdd.domain
      || !this.ssoToAdd.sso
      || this.ssoToAdd.domain.trim() === ''
    ) {
      Toast.open({
        message: 'Please complete all the fields',
        type: 'is-danger',
        position: 'is-bottom',
        duration: 3000,
      });
      return;
    }

    if (!REG_DOMAIN_VALID.exec(this.ssoToAdd.domain)) {
      Toast.open({
        message: 'Please enter a valid domain',
        type: 'is-danger',
        position: 'is-bottom',
        duration: 3000,
      });
      return;
    }

    if (this.ssoList.find(sso => sso.domain === this.ssoToAdd.domain)) {
      Toast.open({
        message: 'This domain is already in the list. Please enter another domain.',
        type: 'is-danger',
        position: 'is-bottom',
        duration: 4000,
      });
      return;
    }

    this.ssoToAdd.domain.trim();
    this.ssoList.push(this.ssoToAdd);
    this.ssoToAdd = new Sso();
  }

  private removeSso(index: number) {
    Dialog.confirm({
      title: 'Confirm',
      message: `
        Are you sure you want to remove this domain?<br/>
        <strong>${this.ssoList[index].domain}</strong>
      `,
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        this.ssoList.splice(index, 1);
      },
    });
  }

  private async save() {
    if (!this.selectedClient) {
      return;
    }

    this.isSaving = true;

    // Save the new values
    await dataLabApiService.putClientSSOManager(
      this.selectedClient.clientId,
      this.ssoEnabled,
      !this.disableQualifioLogin,
      this.ssoList.map(sso => `${sso.sso}.${sso.domain}`),
    );

    this.reset();

    // Display success message
    Notification.open({
      message: 'Success!<br/> Your configuration was successfully saved.',
      position: 'is-top-right',
      type: 'is-success',
      indefinite: true,
    });


    // Emit close event so that the modal is closed
    this.$emit('close');
  }

  private reset() {
    // Reset values
    this.isSaving = false;
    this.isLoadingClientSso = false;
    this.ssoEnabled = false;
    this.disableQualifioLogin = false;
    this.selectedClient = null;
    this.ssoList = [];
    this.ssoToAdd = new Sso();
  }

  private getIdpIcon(idp: string) {
    if (!idp) {
      return { icon: null, pack: null };
    }

    switch (idp) {
      case 'azure':
        return { icon: 'microsoft', pack: 'fab' };
      case 'google':
        return { icon: idp, pack: 'fab' };
      default:
        return { icon: 'magic', pack: 'fas' };
    }
  }
}
