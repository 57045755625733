

































import { Component, Vue } from 'vue-property-decorator';
import { storeTokenFromRoute } from './helpers';

@Component
export default class App extends Vue {
  private authorized: boolean = false;

  async mounted() {
    // Trigger once the window resize handler
    // to force the iframe being sized correctly
    setTimeout(() => this.onWindowResize(), 400);
  }

  async created() {
    // Get and store token
    try {
      await storeTokenFromRoute(this.$router, this.$route, this.$store);
      this.authorized = true;
    } catch (error) {
      this.authorized = false;
    }

    window.addEventListener('resize', this.onWindowResize);
  }

  destroyed() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  // eslint-disable-next-line class-methods-use-this
  onWindowResize() {
    // Send to the parent window the height of the application
    // to set the iframe height correctly
    if (window.parent && window.parent.postMessage) {
      const height = document.getElementsByTagName('html')[0].scrollHeight;
      window.parent.postMessage(['setHeight', height], '*');
    }
  }
}
