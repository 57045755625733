








import { Component, Vue } from 'vue-property-decorator';
import SsoManager from '../components/manager/SsoManager.vue';
import ActionList from '../components/global/ActionList.vue';
import { ActionBoxProps } from '../components/global/ActionBox.vue';

@Component({
  components: {
    ActionList,
  },
})
export default class Manager extends Vue {
  private managerActions: ActionBoxProps[] = [
    {
      title: 'Enable Single Sign-On',
      description: 'Your customer wants you to enable SSO for Qualifio Manager? After completion of the wizard, Google or Azure SSO will be enabled for their account.',
      icon: 'cogs',
      notionPageUrl: 'https://www.notion.so/qualifio/Enable-Qualifio-Single-Sign-On-8952bccafcd9478996d264b982a521b4',
      modal: SsoManager,
    },
  ];
}
