
























import {
  Component,
  Vue,
  Emit,
  Prop,
} from 'vue-property-decorator';

@Component
export default class PasswordWithGeneratorField extends Vue {
  private readonly charSet = 'abcdefghijklmnpqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ123456789!$%&=?+#:';

  @Prop()
  private readonly label!: string;

  @Prop()
  private readonly help!: string;

  private value: string = '';

  @Emit('onChange')
  private generatePassword() {
    // Founded here: https://stackoverflow.com/questions/1497481/javascript-password-generator
    this.value = Array(10)
      .fill('')
      .map(() => this.charSet.charAt(Math.random() * this.charSet.length))
      .join('');
    return this.value;
  }

  @Emit('onChange')
  private onChange(value: string) {
    return value;
  }

  public reset() {
    this.value = '';
  }
}
