import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import Home from '../views/Home.vue';
import Stats from '../views/Stats.vue';
import ArchiveStore from '../views/ArchiveStore.vue';
import Manager from '../views/Manager.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/stats',
    name: 'stats',
    component: Stats,
  },
  {
    path: '/manager',
    name: 'manager',
    component: Manager,
  },
  {
    path: '/archive-store',
    name: 'archive-store',
    component: ArchiveStore,
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
