const defaultConfig = {
  VUE_APP_API_HOST: 'http://localhost:3060/v1/data-lab',
  VUE_APP_AUTH_ADMIN_API_HOST: 'http://localhost:3000/mfe/auth-admin/api',
};

const {
  VUE_APP_API_HOST,
  VUE_APP_AUTH_ADMIN_API_HOST,
} = {
  ...defaultConfig,
  ...process.env,
} as Record<string, string>;

export interface ApiConfig {
  fullPath: string;
}

export const apiConfig: ApiConfig = {
  fullPath: VUE_APP_API_HOST,
};

export interface AuthAdminApiConfig {
  fullPath: string;
}

export const authAdminApiConfig: AuthAdminApiConfig = {
  fullPath: VUE_APP_AUTH_ADMIN_API_HOST,
};
