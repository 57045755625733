



















































import moment from 'moment';
import {
  ToastProgrammatic as Toast,
  DialogProgrammatic as Dialog,
} from 'buefy';
import {
  Component,
  Vue,
  Prop,
  Ref,
} from 'vue-property-decorator';
import { dataLabApiService } from '../../services';
import {
  Client,
  Campaign,
  CampaignType,
  Question,
  Channel,
} from '../../models';
import { triggerDownloadFile } from './helpers';
import SearchClient from '../global/SearchClient.vue';
import SearchCampaign from '../global/SearchCampaign.vue';
import SelectChannel from '../global/SelectChannel.vue';
import SelectQuestion from '../global/SelectQuestion.vue';

@Component({
  components: {
    SearchClient,
    SearchCampaign,
    SelectChannel,
    SelectQuestion,
  },
  data: () => ({
    campaignType: [CampaignType.SURVEY_AND_POLL],
  }),
})
export default class ExportSurveyResults extends Vue {
  @Prop()
  private title!: string;

  @Prop()
  private notionPageUrl!: string;

  @Prop()
  private icon!: string;

  @Ref('selectQuestion')
  private selectQuestion!: SelectQuestion;

  private selectedClient: Client | null = null;

  private selectedCampaign: Campaign | null = null;

  private selectedChannel: Channel | null = null;

  private selectedQuestion: Question | null = null;

  private isExporting: boolean = false;

  private async generateExport() {
    if (this.isExporting) { // prevent multiple click
      return;
    }

    if (
      !this.selectedClient
      || !this.selectedCampaign
      || !this.selectedChannel
      || !this.selectedQuestion
    ) {
      Toast.open({
        message: 'Please complete all the fields',
        type: 'is-danger',
        position: 'is-bottom',
        duration: 3000,
      });
      return;
    }

    this.isExporting = true;

    // Get the parameters
    const { clientId } = this.selectedClient;
    const { campaignId } = this.selectedCampaign;
    const { channelId } = this.selectedChannel;
    const { questionId } = this.selectedQuestion;

    // Call
    const result = await dataLabApiService.getSurveyResults(
      clientId,
      campaignId,
      channelId,
      questionId,
    );

    if (result.status === 404) {
      this.isExporting = false;
      Toast.open({
        message: 'Sorry there\'s no export available for your request',
        type: 'is-danger',
        position: 'is-bottom',
        duration: 5000,
      });
      return;
    }

    // Trigger the download
    triggerDownloadFile(`qualifio_${moment().format('DD-MM-YYYY')}_${campaignId}_${questionId}.csv`, result.data);

    this.isExporting = false;

    const exportOtherQuestion = await this.otherExportForThatCampaign();
    if (exportOtherQuestion) {
      this.selectedQuestion = null;
      this.selectQuestion.reset();
    } else {
      // Reset values
      this.selectedClient = null;
      this.selectedCampaign = null;
      this.selectedChannel = null;
      this.selectedQuestion = null;

      // Emit close event so that the modal is closed
      this.$emit('close');
    }
  }

  private async otherExportForThatCampaign() {
    return new Promise((resolve) => {
      Dialog.confirm({
        message: `
          Would you like to export an other question of this campaign?
        `,
        cancelText: 'No',
        confirmText: 'Yes',
        onConfirm: () => resolve(true),
        onCancel: () => resolve(false),
      });
    });
  }
}
