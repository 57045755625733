















import { Component, Vue } from 'vue-property-decorator';

interface Page {
  name: string;
  icon: string;
  path: string;
}

@Component
export default class Home extends Vue {
  private pages: Page[] = [
    {
      name: 'Export data',
      icon: 'chart-bar',
      path: '/stats',
    },
    {
      name: 'Qualifio SSO',
      icon: 'sign-in-alt',
      path: '/manager',
    },
  ];

  constructor() {
    super();
    // Enable archive store only on local as it's not implemented yet
    // TODO finish it
    if (process.env.NODE_ENV === 'development') {
      this.pages.push({
        name: 'ArchiveStore',
        icon: 'lock-alt',
        path: '/archive-store',
      });
      this.pages.push({
        name: 'Monitoring',
        icon: 'heart-rate',
        path: '/monitoring',
      });
    }
  }
}
