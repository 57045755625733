import Vue from 'vue';
import Buefy from 'buefy';
import App from './App.vue';
import router from './router';
import store from './store';
// ------------------
// Vue configurations

if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'production') {
  Vue.config.productionTip = false;
}

Vue.use(Buefy, {
  defaultIconPack: 'fas', // use Font awesome as default icons pack
});

// ------------------
// Application entry point
window.addEventListener('DOMContentLoaded', async () => {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app');
});
